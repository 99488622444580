import React from "react";
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from '../styles/globalStyle';
import Navbar from './Navbar/Navbar'
import Footer from './footer'

const PageWrapper = styled.div`
  width: 100%;
  margin: 0;
  position: relative;
  bottom: 0;
`

const MainSection = styled.main`
  margin: 30px 5vw;
`

const FooterStyled = styled.footer`
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px;
  text-align: center;
`

class Layout extends React.Component {
 
  render() {
    const {children}  = this.props
    
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <PageWrapper>
            <Navbar />
            <MainSection>{children}</MainSection>
            <FooterStyled>
              <Footer />
            </FooterStyled>
          </PageWrapper>
        </>
      </ThemeProvider>
    )
  }
}

export default Layout
