import React from "react"
import { Link } from "gatsby"
import loadable from '@loadable/component'
import Lockup from "../../../static/assets/lockup.svg"
import styled from 'styled-components'

const CartSummary = loadable(() => import('./CartSummary'))

const Navigation = styled.nav`
    height: 15vh;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 0 2vw;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
`

const LogoWrap = styled(Link)`
  margin-top: 1vh;
  cursor: pointer;
`

const Logo = styled(Lockup)`
    max-height: 8vw;

  @media (max-width: 768px) {
    max-height: 12vw;

  }
`

const Navbar = () => {
    

    return (
        <Navigation>
          <LogoWrap to='/' aria-label="Home Button">
            <Logo />
          </LogoWrap>
          <CartSummary />
        </Navigation>

    )
}

export default Navbar