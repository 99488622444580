// This is the template for each programmatically generated item in the shop. It will be populated with data from markdown files in the content folder.

import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components"
import { Link } from "gatsby-plugin-modal-routing"
import Layout from "../components/layout"

import Canvas from "../components/ItemThumbnail/Canvas"
import ShareLink from "../../static/assets/share.svg"
import Cross from "../../static/assets/Cross.svg"
import Basket from "../components/ItemThumbnail/basket"


const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding-top: 5vh;
  margin: 0 auto;

  @media (max-width: 768px) and (orientation: portrait) {
    grid-template-columns: 1fr;
    }
`

const Leave = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`

const ShareButton = styled.button `
  background-color: unset;
  outline: none;
  cursor: pointer;
  height: 4em;  


  :active {
    transform: scale(0.8);
  }
`
const DetailsColumn = styled.div `
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 18px;
  text-align: center;
  justify-items: space-evenly;

  p {
    font-family: ${({ theme }) => theme.font.serif}, Georgia, serif;
    margin: 5% 10%;
    font-weight: 200;
    font-size: 1em;
    line-height: 1.5;
    color: black;
  }
`

const Dot = styled.div`
    opacity: ${props => (props.dot ? "0" : "1")};
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: red;
    bottom: 2%;
    right: 3%;
    z-index: 1;
`
const OtherButtons = styled.div `
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    padding: 0 4vw;  
    
    @media (max-width: 768px) and (orientation: portrait) {
      >* {
        transform: scale(0.8);
        }
      padding: 0;
    }
`

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.share = this.share.bind(this);
  }


  share() { 
    const item = this.props.data.markdownRemark.frontmatter
    const siteURL = this.props.data.site.siteMetadata.siteUrl 
    if (navigator.share) {
      navigator.share({
        title: `${item.title}`,
        url: `${siteURL}${window.location.pathname}`
      }).then(() => {
        console.log('success');
      })
      .catch(console.error);
    } else {
      const dummy = document.createElement('input');

      document.body.appendChild(dummy);
      dummy.value = `${siteURL}${window.location.pathname}`;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      window.alert('Copied to clipboard')
    }
  }

  render() {
    const item = this.props.data.markdownRemark.frontmatter
    const link = this.props.data.markdownRemark.fields.slug
    const basket = false;
    const { title, image, price, sold, dimensions, description, id, quantity } = item

    return (
      <Layout>
        <Container>
        <div style={{position: 'relative', alignSelf: 'center'}}>
          <Canvas>
          <Img fluid={image.childImageSharp.fluid} />
          {sold ? (
            <Dot />
          ) : (
            <Dot dot />
          )} 
          </Canvas>
          </div>
          <DetailsColumn>
            <OtherButtons>
              <Leave to="/"><ShareButton aria-label="Leave"><Cross /></ShareButton></Leave>
              <ShareButton onClick={this.share} aria-label="Share"><ShareLink /></ShareButton>
            </OtherButtons>
            <h1 style={{whiteSpace: 'nowrap'}}>{title}</h1>
            <p>{description}</p>
            <p dangerouslySetInnerHTML={{ __html: this.props.data.markdownRemark.html }} />
            <p>{dimensions.width}cm x {dimensions.height}cm</p>
            <p>£{price}</p>
            <Basket basket={basket} 
              link={link} 
              id={id}
              quantity={quantity}
              price={price}
              sold={sold}
              name={title}
              descriptio={description}
              image={image.childImageSharp.fluid.src}
              width={dimensions.width}
              weight={(dimensions.height*dimensions.width)*0.42}
              height={dimensions.height} 
            />
            </DetailsColumn>
        </Container>
      </Layout>
    )
  }
}

export default Item;


export const pageQuery = graphql`
query ProductBySlug($slug: String!) {
  site {
    siteMetadata {
      siteUrl
      title
    }
  }
  markdownRemark(fields: { slug: { eq: $slug } }) {
    html
    fields {
      slug
    }
    frontmatter {
      title
      id
      description
      quantity
      price
      sold
      dimensions {
        width
        height
      }
      image {
        childImageSharp {
          fluid(maxWidth: 480) {
            src
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`

