import { createGlobalStyle } from 'styled-components'

export const theme = {
   font: {
     sans: 'montserrat, sans-serif',
     serif: 'merriweather, serif',
   },
 }

export const GlobalStyle = createGlobalStyle`
   
   *, *:before, *:after {
    box-sizing: border-box;
   }

   html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.sans}, Georgia, serif;
    font-size: 16px;
    color: '#1a202c';
   }

   body {
    line-height: 1.5;
    letter-spacing: 0;
    background-color: '#f7fafc';
    margin: 0;
    overflow-x: hidden;
   }

   h1 {
    font-family: ${({ theme }) => theme.font.serif}, Georgia, serif;
    font-size: 2em;
    font-weight: 500;
    color: black;
   }

   h2 {
    font-family: ${({ theme }) => theme.font.serif}, Georgia, serif;
    font-size: 2em;
    font-weight: 200;
    color: black;
   }

   h3 {
    font-family: ${({ theme }) => theme.font.sans}, Verdana, sans-serif;
    font-size: 0.75em;
    font-weight: 400;
    color: black;
   }
   
   p {
    font-family: ${({ theme }) => theme.font.sans}, Verdana, sans-serif;
    font-size: 1em;
    font-weight: 300;
    color: black;
   }

   .details {
    font-family: ${({ theme }) => theme.font.serif}, Georgia, serif;
    margin: 5% 10%;
    font-weight: 200;
    font-size: 1em;
    line-height: 1.5;
    color: black;
   }
   
   a {
    font-family: ${({ theme }) => theme.font.sans}, Verdana, sans-serif;
    font-size: 1em;
    font-weight: 300;
    
   }

   ul, li {
    font-family: ${({ theme }) => theme.font.sans}, Verdana, sans-serif;
    font-size: 0.75em;
    line-height: 2;
    font-weight: 400;
    text-decoration: none;
   }

   input {
    width: fill-available;
    font-family: ${({ theme }) => theme.font.sans}, Verdana, sans-serif;
    font-size: 0.75em;
    line-height: 2;
    font-weight: 400;
    text-decoration: none;
    border-style: none;
    border-bottom: 1px solid gray;

    :focus, :active, -webkit-autofill:active, :-internal-autofill-selected {
      background-color: none !important;
      outline: none;
      border: thin solid gray;
    }
   }

   textarea {
    width: fill-available;
    font-family: ${({ theme }) => theme.font.sans}, Verdana, sans-serif;
    font-size: 0.75em;
    line-height: 2;
    font-weight: 400;
    text-decoration: none;
    border-style: none;
    border-bottom: 1px solid gray;

    :focus {
      background-color: none;
      outline: none;
      border: thin solid gray;
    }
   }

   button {
    font-family: ${({ theme }) => theme.font.serif}, Georgia, serif;
    font-size: 0.75em;
    font-weight: 400;
    color: white;
    border: none !important;
   }

   @media (max-width: 768px){
      h1 {font-size: 1em;}
  }

/* Snipcart Styles */

.snip-layout div{
  border-radius: 12px !important;
}

.snip-layout .snip-header {
  font-family: ${({ theme }) => theme.font.serif}, Georgia, serif !important;
  text-transform: unset  !important;
  background-color: white !important;
  
}

.snip-layout .snip-header__title {
  color: black !important;
}

.snip-layout .snip-header__total {
  background-color: white !important;

}

.snip-layout a, .snip-layout button {
  font-family: ${({ theme }) => theme.font.sans}, Georgia, serif !important;
  font-weight: 400 !important;
  color: black !important;
}

.snip-layout .snip-btn {
  color: white !important;
}

.snip-layout .snip-btn--highlight {
  background-color: rgb(43, 124, 111) !important;
}

.snip-layout .snip-header__continue {
  color: white !important;
}

#snipcart-amount {
  color: black !important;
}

.snip-layout .snip-table td, .snip-ico--close:before {
  font-family: Arial, Helvetica, sans-serif !important;
}

.snip-layout .snip-layout button {
background-color: #ddd !important;
}

.snip-layout .snip-footer {
  background: #fff !important;
  padding: .125em .625em;
  text-transform: unset !important;
}

.snip-layout #snipcart-discounts {
  display: none !important;
}

.snip-layout .snip-ico--close:before {
  font-family: Snipcart !important;
}

`

