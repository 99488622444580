import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-modal-routing"

import '../styles/Modal.css'

const NavItem = styled(Link)`
  text-decoration: none;
  font-size: 0.75em;
  font-weight: 400;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  z-index: 0;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 0.75em;
    z-index: 6;
  }
`

const Footer = (props, {location} ) => {

  return (
      <div>
      <NavItem to="/">Home</NavItem>
      <NavItem to="/cookie" asModal>Cookie Policy</NavItem>
      <NavItem to="/privacy" asModal>Privacy Policy</NavItem>
      <NavItem to="/terms" asModal>Terms & Conditions</NavItem>
      <NavItem to="/returns" asModal>Returns Policy</NavItem>
      <NavItem to="/contact">Contact</NavItem>
      </div>
    )
}

export default Footer